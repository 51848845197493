import { render, staticRenderFns } from "./demo.jetx.vue?vue&type=template&id=77caf941&scoped=true&"
import script from "./demo.jetx.vue?vue&type=script&lang=js&"
export * from "./demo.jetx.vue?vue&type=script&lang=js&"
import style0 from "./demo.jetx.vue?vue&type=style&index=0&id=77caf941&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77caf941",
  null
  
)

export default component.exports