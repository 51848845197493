<template>
    <div id="slot">
        <div class="wrapper">
            <template v-if="gameUrl">
                <iframe :src="gameUrl" scrolling="no" frameborder="0" allow=“autoplay;clipboard-write;fullscreen”></iframe>
            </template>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                url:'https://eu-server.ssgportal.com/GameLauncher/Loader.aspx',
                gameCategory:'JetX',
                gameName:'JetX',
                token:'DEMO',
                portalName:'demo',
                returnUrl:'https://rahisibet.com',
                gameUrl:null
            }
        },
        mounted() {
            let str =`${this.url}?GameCategory=${this.gameCategory}&GameName=${this.gameName}&Token=${this.token}&PortalName=${this.portalName}&Lang=${this.$i18n.locale}`;
            this.gameUrl = str;
        },
    }
</script>
<style scoped>
    .main-body {
        padding-top: 120px !important;
    }
    iframe {
        min-width: 95vw;
        min-height: 85vh;
        width: 100%;
        height: 100%;
    }
    .reset {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .full-screen {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px
    }
    .full-screen span {
        cursor: pointer;
    }
</style>